<template>
  <div>
    <div class="search">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input
            v-model="queryParams.name"
            placeholder="课程名称"
            size="small"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-select
            size="small"
            v-model="queryParams.ctype"
            placeholder="课程类型"
          >
             <el-option
              v-for="item in courseType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="15">
          <el-button size="small" type="default" class="fr" @click="reset"
            >重置</el-button
          >
          <el-button
            size="small"
            type="primary"
            class="fr"
            style="margin-right: 12px"
            @click="search"
            >查询</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="tableBlock" v-loading="loading">
      <!-- <div class="columns-btn" v-if="hasColumnBtn">
        <el-button
          type="success"
          icon="el-icon-plus"
          @click="openDialog"
          v-if="hasAuth('cms.pbty.add')"
          >添加</el-button
        >
      </div> -->
      <el-table
        :data="tableData"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        style="width: 100%"
        border
      >
        <el-table-column label="封面" :show-overflow-tooltip="true" width="110">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.cover_url"
              class="coverImg"
              fit="cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="课程" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.name | textFormat
          }}</template>
        </el-table-column>
        <el-table-column label="课程类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.ctype == 1 ? "专栏" : "视频" | textFormat
          }}</template>
        </el-table-column>
        <el-table-column label="课时" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>
              {{ scope.row.ctype == 1 ? scope.row.course_count : "-" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.created_time | secondFormat("LLL")
          }}</template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)"
              >进入学习</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              @click="deleteData(scope.row)"
              >删除</el-button
            >-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        layout="total, prev, pager, next"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="limit"
      ></el-pagination>
    </div>
    <orderDetail ref="orderDetail"></orderDetail>
  </div>
</template>
<script>
import orderDetail from "~bac/components/order/orderDetail";
// import { orderStatusFormat, paymentMethodFormat } from "@/filters";

// import { getOrderList } from "@/api/course";
export default {
  components: { orderDetail },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      limit: 15,
      tableHeight: 100,
      hasColumnBtn: false, //当页面需要新增按钮时，置为true
      loading: false,
      courseType: [
        {
          value: 1,
          label: "专栏",
        },
        {
          value: 2,
          label: "视频",
        },
      ],
      queryParams: {
        name: "",
      },
      total: 0,
      tableData: [],
    };
  },
  watch: {
    currentPage() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      let _params = Object.assign({}, this.queryParams);
      Object.keys(_params).forEach((item) => {
        _params[item] = null;
      });
      this.queryParams = _params;
    },
    search() {
      let obj = Object.assign({}, this.queryParams);
      if (this.queryParams.time) {
        let start_time = this.$moment(this.queryParams.time[0]).unix();
        let end_time = this.$moment(this.queryParams.time[1]).unix();
        obj.start_time = start_time;
        obj.end_time = end_time;
        delete obj.time;
      }
      this.currentPage = 1;
      this.getList(obj);
    },
    // 获取数据
    async getList(data) {
      this.loading = true;
      let params = {
          start: this.currentPage - 1,
          limit: this.limit,
          nopage: 0,
          source: this.PJSource,
          user_id: this.USER_INFO.id,
          language: this.LOCALE == "zh" ? 1 : 2,
        },
        _params;
      _params = Object.assign(params, data);
      let res = await this.$store.dispatch(
        "baseConsole/getMyCourseList",
        _params
      );
      if (res.success) {
        this.tableData = res.data;
        this.tableData.forEach((item) => {
          item.previewList = [];
          if (item.avatar) {
            item.previewList.push(item.avatar);
          }
        });
        this.total = res.count;
        this.loading = false;
      } else {
        this.loading = false;
      }
      this.loading = false;
      //this.total = res.total;
    },
    edit(scope) {
      let routeUrl = this.$router.resolve({
        path: "/college_detail",
        query: {
          parameter: this._encode({
            video_id: scope.id,
          }),
        },
      });
      window.open(routeUrl.href, "_blank");
      //   this.$nextTick((e) => {
      //     this.$refs.orderDetail.show(scope, 1);
      //   });
    },
  },
};
</script>
<style scoped lang="less">
.uploadwrap {
  max-width: 850px;
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;

  .meetingintroen,
  .meetingintrozh {
    width: 50%;
  }

  .pcimg,
  .pcimgen {
    height: 211px;

    .el-image {
      width: 497px;
      height: 200px;
      border: 1px solid #ccc;
    }

    .el-icon-plus {
      width: 497px;
      height: 200px;
      font-size: 50px;
      text-align: center;
      line-height: 200px;
      border: 1px solid #ccc;
      color: #999;
      cursor: pointer;
    }
  }
  .logoimg {
    height: 80px;
    .el-image {
      width: 72px;
      height: 66px;
      border: 1px solid #ccc;
    }
    .el-icon-plus {
      width: 72px;
      height: 66px;
      font-size: 30px;
      text-align: center;
      line-height: 66px;
      border: 1px solid #ccc;
      color: #999;
      cursor: pointer;
    }
  }
}
.coverImg {
  width: 80px;
  height: 50px;
}
.companyAvatar {
  width: 120px;
  height: 120px;
  overflow: hidden;
}
.search {
  padding: 24px 12px;
}
</style>
