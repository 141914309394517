<template>
  <div>
    <el-dialog :title="'订单号'" :visible.sync="dialogVisible" width="1200px">
      <div v-loading="loading">
        <div class="iconContent">
          <div v-if="orderData.status == 0 && !isLose">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/image/icon/unpaid.png"
              class="iconImg"
            />
            <span>待支付</span>
          </div>
          <div v-if="orderData.status == 1">
            <!-- <i class="el-icon-success successColor"></i> -->
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/image/icon/success.png"
              class="iconImg"
            />
            <span>交易完成</span>
          </div>
          <div v-if="orderData.status == 2 || isLose">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/image/icon/cencel.png"
              class="iconImg"
            />
            <span>{{ isLose ? "已失效" : "已取消" }}</span>
          </div>
          <div v-if="orderData.status == 3 || orderData.status == 4">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/image/icon/closed.png"
              class="iconImg"
            />
            <span>交易关闭</span>
          </div>
          <div v-if="orderData.status == 5">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/image/icon/refundStay.png"
              class="iconImg"
            />
            <span>待退款</span>
          </div>
        </div>
        <div class="orderContent">
          <div class="orderTitle">订单信息</div>
          <div class="clearfix">
            <div class="orderItem fl clearfix">
              <div class="fl label">订单号：</div>
              <div class="fl value">{{ orderData.order_no | textFormat }}</div>
            </div>
            <!-- <div class="orderItem fl clearfix">
              <div class="fl label">订单类型：</div>
              <div class="fl value">课程订单</div>
            </div> -->
            <div class="orderItem fl clearfix">
              <div class="fl label">订单状态：</div>
              <div class="fl value">
                <span v-if="isLose">已失效</span>
                <span v-else>{{
                  orderData.status | orderStatusFormat(LOCALE)
                }}</span>
              </div>
            </div>
            <div class="orderItem fl clearfix">
              <div class="fl label">支付方式：</div>
              <div class="fl value">
                {{ orderData.payment | paymentMethodFormat(LOCALE) }}
              </div>
            </div>
            <div class="orderItem fl clearfix">
              <div class="fl label">下单时间：</div>
              <div class="fl value">
                {{ orderData.created_time | secondFormat("LLL") }}
              </div>
            </div>

            <!-- <div class="orderItem fl clearfix">
              <div class="fl label">优惠信息：</div>
              <div class="fl value">会员折扣</div>
            </div>
            <div class="orderItem fl clearfix">
              <div class="fl label">渠道来源：</div>
              <div class="fl value">
                {{ orderData.tenant_name | textFormat }}
              </div>
            </div>-->
            <div class="orderItem fl clearfix">
              <div class="fl label">付款时间：</div>
              <div class="fl value">
                {{ orderData.pay_time | secondFormat("LLL") }}
              </div>
            </div>
            <!-- <div class="orderItem fl clearfix">
              <div class="fl label">结算时间：</div>
              <div class="fl value">2021-07-04 20:49:55</div>
            </div> -->
            <!-- <div class="orderItem fl clearfix">
              <div class="fl label">售后状态：</div>
              <div class="fl value">--</div>
            </div> -->
            <!-- <div class="orderItem fl clearfix">
              <div class="fl label">分销信息：</div>
              <div class="fl value">--</div>
            </div> -->
          </div>
          <div class="orderTitle">支付信息</div>
          <div class="clearfix">
            <div class="orderItem fl clearfix">
              <div class="fl label">交易单号：</div>
              <div class="fl value">
                {{ orderData.escrow_trade_no | textFormat }}
              </div>
            </div>
            <div class="orderItem fl clearfix">
              <div class="fl label">商户单号：</div>
              <div class="fl value">
                {{ orderData.out_trade_no | textFormat }}
              </div>
            </div>
          </div>
          <!-- <div class="orderTitle">发起方信息</div>
          <div class="clearfix">
            <div class="orderItem fl clearfix">
              <div class="fl label">姓名：</div>
              <div class="fl value">
                {{ orderData.buyer_user_name | textFormat }}
              </div>
            </div>
            <div class="orderItem fl clearfix">
              <div class="fl label">公司：</div>
              <div class="fl value">
                {{ orderData.buyer_company_name | textFormat }}
              </div>
            </div>
            <div class="orderItem fl clearfix">
              <div class="fl label">联系方式：</div>
              <div class="fl value">-</div>
            </div>
          </div>
          <div class="orderTitle">交易对方信息</div>
          <div class="clearfix">
            <div class="orderItem fl clearfix">
              <div class="fl label">姓名：</div>
              <div class="fl value">
                {{ orderData.seller_user_name | textFormat }}
              </div>
            </div>
            <div class="orderItem fl clearfix">
              <div class="fl label">公司：</div>
              <div class="fl value">
                {{ orderData.seller_company_name | textFormat }}
              </div>
            </div>
            <div class="orderItem fl clearfix">
              <div class="fl label">联系方式：</div>
              <div class="fl value">-</div>
            </div>
          </div> -->
          <!-- <div class="orderTitle">发票信息</div>
          <div class="clearfix">
            <div class="orderItem fl clearfix">
              <div class="fl label">发票抬头：</div>
              <div class="fl value">南院大王投资有限公司</div>
            </div>
            <div class="orderItem fl clearfix">
              <div class="fl label">企业税号：</div>
              <div class="fl value">xxxxxxxxxxx</div>
            </div>
            <div class="orderItem fl clearfix">
              <div class="fl label">联系方式：</div>
              <div class="fl value">+86 15000753568</div>
            </div>
          </div> -->
          <div class="tablePanel">
            <el-table
              :data="orderData.tableData"
              :height="'98px'"
              style="width: 100%"
              border
            >
              <el-table-column label="下单时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.created_time | secondFormat("LLL")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="商品类型" :show-overflow-tooltip="true">
                <template slot-scope="scope">{{
                  scope.row.goods_type == 1 ? "课程" : "询盘" | textFormat
                }}</template>
              </el-table-column>
              <el-table-column label="单价" :show-overflow-tooltip="true">
                <template slot-scope="scope">{{
                  scope.row.amount_paid / 100
                }}</template>
              </el-table-column>
              <el-table-column label="数量" :show-overflow-tooltip="true">
                <template slot-scope="scope">1</template>
              </el-table-column>
              <!-- <el-table-column label="优惠" :show-overflow-tooltip="true">
                <template slot-scope="scope">{{
                  scope.row.amount_total - scope.row.amount_paid
                }}</template>
              </el-table-column> -->
              <el-table-column label="合计" :show-overflow-tooltip="true">
                <template slot-scope="scope">{{
                  orderData.amount_paid / 100
                }}</template>
              </el-table-column>
            </el-table>
            <div class="tr total">
              <span
                >金额（￥）：<span>{{
                  orderData.payment != 5 && orderData.payment != 6
                    ? orderData.amount_paid / 100
                    : "0"
                }}</span></span
              >
              <!-- <span
                >积分：<span>{{
                  orderData.payment == 6 ? orderData.amount_paid : "-"
                }}</span></span
              >
              <span
                >条数：<span>{{
                  orderData.payment == 5 ? orderData.amount_paid : "-"
                }}</span></span
              > -->
            </div>
          </div>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
// import {
//   commodityTypeFormat,
//   paymentMethodFormat,
//   orderStatusFormat,
// } from "@/filters";
// import { getOrderList } from "@/api/course";

export default {
  data() {
    return {
      dialogVisible: false,
      orderData: {},
      loading: false,
      isLose: 0,
    };
  },
  methods: {
    show(row, type) {
      this.dialogVisible = true;
      this.isLose = type;
      this.getDetail(row, type);
    },
    async getDetail(row, type) {
      console.log(row);
      this.loading = true;
      try {
        let result = await this.$store.dispatch("baseConsole/getOrderDetail", {
          order_id: row.id,
        });
        // getOrderList(params).then((result) => {
        if (result.success) {
          this.orderData = result.data;
          this.orderData.tableData = [result.data];
        }
        // });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.iconContent {
  height: 88px;
  width: 100%;
  background: #f5f5f6;

  div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    font-size: 16px;
  }
  i {
    font-size: 32px;
    margin-right: 10px;
  }
}
.orderTitle {
  font-weight: bold;
  margin-top: 30px;
}
// .orderContent{
//     padding: 30px 0;
// }
.iconImg {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.orderItem {
  width: 33.3%;
  margin-top: 20px;
  .label {
    width: 95px;
  }
  .value {
    width: 280px;
  }
}
.tablePanel {
  margin-top: 30px;
  // border-left: 1px solid #ebeef5;
  // border-right: 1px solid #ebeef5;
}
.total {
  padding: 13px 16px;
  border: 1px solid #ebeef5;
  border-top: none;
  & > span {
    margin-left: 24px;
  }
}
.tr {
  text-align: right;
}
</style>